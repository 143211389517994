import React from 'react';
import './RemarksComponent.css';

const RemarksComponent = (props) => {
  const defaultText = "No remarks available.";

  return (
    <div>
      <div className="remarks-header">
        <h3>Remarks</h3>
      </div>
      <p className="remark-content">{props.text || defaultText}</p>
    </div>
  );
};

export default RemarksComponent;
