// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-legal-opinion {
    padding: 20px;
  }
  
  .new-legal-opinion .form-group {
    margin-bottom: 15px;
  }
  
  .new-legal-opinion .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .new-legal-opinion .form-group input,
  .new-legal-opinion .form-group select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .new-legal-opinion button {
    padding: 10px 15px;
    margin-right: 10px;
  }
  
  .create-legal-opinio-button{
    background-color: #FF6138;
    border: 1px solid #FF6138;
    border-radius: 22px; /* half of 44px */
    width: auto;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    cursor: pointer;
    padding: 0 10px;
    margin-right: 50px;
  }
  .cancel-legal-opinio-button{
    background-color: #FF6138;
    border: 1px solid #FF6138;
    border-radius: 22px; /* half of 44px */
    width: auto;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    cursor: pointer;
    padding: 0 10px;
    margin-right: 50px;
  }`, "",{"version":3,"sources":["webpack://./src/components/NewLegalOpinion/NewLegalOpinion.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB;;EAEA;;IAEE,WAAW;IACX,YAAY;IACZ,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB,EAAE,iBAAiB;IACtC,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,eAAe;IACf,kBAAkB;EACpB;EACA;IACE,yBAAyB;IACzB,yBAAyB;IACzB,mBAAmB,EAAE,iBAAiB;IACtC,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,eAAe;IACf,kBAAkB;EACpB","sourcesContent":[".new-legal-opinion {\n    padding: 20px;\n  }\n  \n  .new-legal-opinion .form-group {\n    margin-bottom: 15px;\n  }\n  \n  .new-legal-opinion .form-group label {\n    display: block;\n    margin-bottom: 5px;\n  }\n  \n  .new-legal-opinion .form-group input,\n  .new-legal-opinion .form-group select {\n    width: 100%;\n    padding: 8px;\n    box-sizing: border-box;\n  }\n  \n  .new-legal-opinion button {\n    padding: 10px 15px;\n    margin-right: 10px;\n  }\n  \n  .create-legal-opinio-button{\n    background-color: #FF6138;\n    border: 1px solid #FF6138;\n    border-radius: 22px; /* half of 44px */\n    width: auto;\n    height: 44px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: white;\n    font-size: 14px;\n    cursor: pointer;\n    padding: 0 10px;\n    margin-right: 50px;\n  }\n  .cancel-legal-opinio-button{\n    background-color: #FF6138;\n    border: 1px solid #FF6138;\n    border-radius: 22px; /* half of 44px */\n    width: auto;\n    height: 44px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: white;\n    font-size: 14px;\n    cursor: pointer;\n    padding: 0 10px;\n    margin-right: 50px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
