import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import SideBar from '../SideBar/SideBar';
import PropertyDetails from '../PropertyDetails/PropertyDetails';
import Header from '../Header/Header'; 
import './Home.css'; 
import { UserContext } from '../Context/UserContext';
import { useNavigate } from 'react-router-dom';
import NewLegalOpinion from '../NewLegalOpinion/NewLegalOpinion'; // Import the new component


const Home = () => {
  const [legalOpinions, setLegalOpinions] = useState([]);
  const [selectedOpinion, setSelectedOpinion] = useState(null);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [showNewLegalOpinion, setShowNewLegalOpinion] = useState(false); 

  const fetchLegalOpinions = async () => {
    try {
      const response = await axios.get(
        `/api/legal-opinion/user/${user ? user.phoneNumber : ''}`
      );
      setLegalOpinions(response.data);
    } catch (error) {
      console.error('Error fetching legal opinions:', error);
    }
  };

  useEffect(() => {
    // Redirect to login if user is not authenticated
    if (!user) {
      navigate('/', { replace: true }); // Replace current entry in history
    }
  }, [user, navigate]);

  useEffect(() => {
    if (user) {
      const phoneNumber = user.phoneNumber;
      axios.get(`/api/legal-opinion/user/${phoneNumber}`)
        .then(response => {
          setLegalOpinions(response.data);
        })
        .catch(error => {
          console.error('Error fetching legal opinions:', error);
        });
    }
  }, [user]);

  useEffect(() => {
    fetchLegalOpinions();
  }, []);

  const handleItemClick = (item) => {
    console.log('Item Clicked');
    setSelectedOpinion(item);
  };

  const handleNewOpinionClick = () => {
    setShowNewLegalOpinion(true);
  };

  const handleNewOpinionCreated = () => {
    setShowNewLegalOpinion(false); // Hide NewLegalOpinion component
    fetchLegalOpinions(); // Refresh legal opinions
  };

  return (
    <div className="home-container">
      <Header onNewOpinionClick={handleNewOpinionClick}/>
      <div className="content-container">
        <SideBar phoneNumber={user ? user.phoneNumber : ''} items={legalOpinions} onItemClick={handleItemClick} />
        <div className="content">
          {showNewLegalOpinion ? (
            <NewLegalOpinion onCancel={() => setShowNewLegalOpinion(false)} onNewOpinionCreated={handleNewOpinionCreated} />
          ) : selectedOpinion ? (
            <PropertyDetails item={selectedOpinion} phoneNumber={user ? user.phoneNumber : ''} />
          ) : (
            <div className="home-center-logo-container">
              <img src={require('../../assets/images/brosky_legal_with_subtitle.png')} alt="Logo" className="home-center-logo" />
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default Home;
