import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faFileDownload,
  faHourglass,
  faTimesCircle,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import './PropertyListItem.css'; 

const PropertyListItem = ({ phoneNumber, item}) => {
  const { id, name, verificationStatus, safetyScore } = item;
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadFile = async () => {
    setIsDownloading(true);

    const url = `/api/document/downloadLegalOpinion/${encodeURIComponent(phoneNumber)}/${id}`;
    const fileName = `legal_opinion_document_${id}.pdf`;

    try {
      const response = await axios({
        url,
        method: 'GET',
        responseType: 'blob',
      });

      const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setIsDownloading(false);
      alert('Success! File downloaded successfully.');
    } catch (error) {
      setIsDownloading(false);
      console.error('File download error:', error);
      alert('Error! File download failed.');
    }
  };

  const getStatusIcon = () => {
    switch (verificationStatus) {
      case 'VERIFICATION_COMPLETE':
        return <FontAwesomeIcon icon={faCheckCircle} size="lg" color="#34A853" />;
      case 'Pending Documentation Upload':
      case 'VERIFICATION_PENDING':
        return <FontAwesomeIcon icon={faTimesCircle} size="lg" color="#EA4335" />;
      case 'Verification In Progress':
        return <FontAwesomeIcon icon={faHourglass} size="lg" color="#FFA500" />;
      default:
        return null;
    }
  };

  const renderDownloadIcon = () => {
    if (verificationStatus === 'VERIFICATION_COMPLETE') {
      return (
        <div>
          <button className="download-button" onClick={downloadFile} disabled={isDownloading}>
            <FontAwesomeIcon icon={faFileDownload} size="lg" color="#007BFF" />
            <span style={{marginLeft: 5}}>Click here to download the legal opinion</span>
          </button>
          {isDownloading && <div> <div className="spinner"></div><span style={{marginLeft: 5}}>Downloading...</span></div> }
        </div>
      );
    }
    return null;
  };

  let safetyScoreColor = '#EA4335'; // Default to red
  if (safetyScore >= 90) {
    safetyScoreColor = '#34A853'; // Green for scores above or equal to 90
  } else if (safetyScore >= 80 && safetyScore <= 89) {
    safetyScoreColor = '#FF9900'; // Orange for scores between 80 to 89
  }

  return (
    <div className="container">
      <div className="info-container">
        <h2 className="name">{name}</h2>
        <div className="status-container">
          {getStatusIcon()}
          <span className="status">{verificationStatus}</span>
        </div>
        <p className="id">Legal Opinion ID: {id}</p>
        <p className="safety-score" style={{ color: safetyScoreColor }}>
          {safetyScore === 0 ? '...' : safetyScore}
        </p>
        <p className="safety-score-text">Safety Score</p>
        {renderDownloadIcon()}
        <div className="next-icon-container">
          <FontAwesomeIcon icon={faAngleRight} size="lg" color="gray" />
        </div>
      </div>
    </div>
  );
};

export default PropertyListItem;
