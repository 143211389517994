import React, { useContext, useState } from 'react';
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import './Login.css';
import { UserContext } from '../Context/UserContext';
import axios from 'axios';

const Login = () => {
  const [phNo, setPhNo] = useState('');
  const [otpwd, setOtpwd] = useState('');
  const [name, setName] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const configureCaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        onSignInSubmit();
        console.log('Recaptcha verified');
      },
      defaultCountry: "IN",
    });
  };

  const onSignInSubmit = (e) => {
    e.preventDefault();
    configureCaptcha();
    const phoneNumber = phNo;
    console.log("phone number =", phoneNumber);

    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, '+91' + phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        console.log("OTP has been sent");
        setIsOtpSent(true);
      }).catch((error) => {
        console.log("Error: SMS not sent", error);
      });
  };

  const updateUserInfo = async (userName, phNo) => {
    try {
      const response = await axios.post('/api/user/upsert', {
        name: userName,
        phoneNumber: phNo,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const statusCode = response.status;
      if (statusCode === 200) {
        // The request was successful, handle the response data
        console.log('User upserted successfully!');
      } else if (statusCode === 400) {
        // Handle bad request error (e.g., display an error message)
        console.error('Bad request. Please check the user data.');
      } else {
        // Handle other unexpected status codes
        console.error('An error occurred:', statusCode);
      }
    } catch (e) {
      console.log('unable to update user info', e);
    }
  };

  const onSubmitOTP = (e) => {
    e.preventDefault();
    const code = otpwd;
    window.confirmationResult.confirm(code).then((result) => {
      const user = result.user;
      setUser(user);
      updateUserInfo(name, user.phoneNumber); // Update user info with the entered name
      navigate('/home');
    }).catch((error) => {
      console.error("Error during confirmationResult.confirm", error);
      alert('Invalid OTP. Please try again.');
    });
  };

  return (
    <div className="background">
      <div className="login-container">
        <div className="login-box">
          {!isOtpSent ? (
            <form onSubmit={onSignInSubmit}>
              <div id="sign-in-button"></div>
              <input
                type="text"
                placeholder='Enter your name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <input
                type="number"
                name="mobile"
                placeholder='Enter Mobile Number'
                required
                onChange={(e) => setPhNo(e.target.value)}
              />
              <button type="submit">Login</button>
            </form>
          ) : (
            <>
              <h2>Enter OTP</h2>
              <form onSubmit={onSubmitOTP}>
                <input
                  type="number"
                  name="otp"
                  placeholder='OTP Number'
                  required
                  onChange={(e) => setOtpwd(e.target.value)}
                />
                <button type="submit">Submit</button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
