import CryptoJS from 'crypto-js';

const hashKey = 's@dl2vjsanb#3526gqw';

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), hashKey).toString();
};

export const decryptData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, hashKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
