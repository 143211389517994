// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remarks-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 2px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #CCCCCC;
  }
  
  .remark-content {
    text-align: justify;
    margin-bottom: 10px;
    padding: 0 15px;
    color: #000000;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/RemarksComponent/RemarksComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;IAChB,yBAAyB;IACzB,gCAAgC;EAClC;;EAEA;IACE,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;IACf,cAAc;EAChB","sourcesContent":[".remarks-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 8px 2px;\n    background-color: #FFFFFF;\n    border-bottom: 1px solid #CCCCCC;\n  }\n  \n  .remark-content {\n    text-align: justify;\n    margin-bottom: 10px;\n    padding: 0 15px;\n    color: #000000;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
