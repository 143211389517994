// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableContainer {
    margin: 10px 10px 20px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
  }
  .topicName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 2px;
    background-color: #FFFFFF;
    border-bottom: 1px solid #CCCCCC;
  }
  .row {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid #ccc;
  }
  .cell {
    flex: 1 1;
    font-size: 16px;
    color: #000000;
  }
  .left-align {
    text-align: left;
    padding-left: 10px;
  }
  .right-align {
    text-align: left;
    padding-right: 10px;
  }
  .header-left {
    font-weight: bold;
    text-align: left;
    padding-left: 10px;
  }
  .header-right {
    font-weight: bold;
    text-align: left;
    padding-right: 10px;
  }
  .header-row {
    border-bottom: 2px solid #ccc;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/DocumentDescriptionSection/DocumentDescriptionSection.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;EACf;EACA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;IAChB,yBAAyB;IACzB,gCAAgC;EAClC;EACA;IACE,aAAa;IACb,8BAA8B;IAC9B,cAAc;IACd,6BAA6B;EAC/B;EACA;IACE,SAAO;IACP,eAAe;IACf,cAAc;EAChB;EACA;IACE,gBAAgB;IAChB,kBAAkB;EACpB;EACA;IACE,gBAAgB;IAChB,mBAAmB;EACrB;EACA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;EACpB;EACA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;EACrB;EACA;IACE,6BAA6B;EAC/B","sourcesContent":[".tableContainer {\n    margin: 10px 10px 20px 10px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    padding: 10px;\n  }\n  .topicName {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 8px 2px;\n    background-color: #FFFFFF;\n    border-bottom: 1px solid #CCCCCC;\n  }\n  .row {\n    display: flex;\n    justify-content: space-between;\n    padding: 8px 0;\n    border-bottom: 1px solid #ccc;\n  }\n  .cell {\n    flex: 1;\n    font-size: 16px;\n    color: #000000;\n  }\n  .left-align {\n    text-align: left;\n    padding-left: 10px;\n  }\n  .right-align {\n    text-align: left;\n    padding-right: 10px;\n  }\n  .header-left {\n    font-weight: bold;\n    text-align: left;\n    padding-left: 10px;\n  }\n  .header-right {\n    font-weight: bold;\n    text-align: left;\n    padding-right: 10px;\n  }\n  .header-row {\n    border-bottom: 2px solid #ccc;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
