import React from 'react';
import './DocumentDescriptionSection.css';

const DocumentDescriptionSection = () => {
  const data = [
    { document: 'Title Document', status: 'To check ownership proof' },
    { document: 'Sale Deed', status: 'To check transfer contract' },
    { document: 'Parent Document', status: 'To check ownership history' },
    { document: 'Revenue Record', status: 'Patta / Chitta' },
    { document: 'Property Tax Receipt', status: 'To check Tax payments' },
    { document: 'Approval Documents', status: 'DTCP / RERA' },
    { document: 'EC for 30 years', status: 'Encumbrance verification' },
    { document: 'Case Documents', status: 'To check legal disputes' },
  ];

  return (
    <div>
      <div className="topicName">
        <h3>Document Description</h3>
      </div>
      <p style={{ marginLeft: 15, color: 'gray' }}>
        Upload the documents you have. Brosky will verify the documents and update the status in short time.
      </p>
      <div className="tableContainer">
        <div className="row header-row">
          <div className="cell header-left">Document</div>
          <div className="cell header-right">Description</div>
        </div>

        {data.map((item, index) => (
          <div key={index} className="row">
            <div className="cell left-align">{item.document}</div>
            <div className="cell right-align">{item.status}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentDescriptionSection;
