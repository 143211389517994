// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden; /* Prevent scroll on the container */
}

.content-container {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  margin-top: 60px; /* Space for the fixed header */
  height: calc(100vh - 60px); /* Adjust height to fit below the header */
}

.sidebar {
  width: 250px; /* Adjust width as needed */
  flex-shrink: 0;
  height: 100%; /* Make the sidebar take up full height of the content-container */
  overflow-y: auto; /* Enable scrolling */
}

.content {
  flex: 1 1;
  padding: 20px;
  overflow-y: auto;
}

.home-center-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.home-center-logo {
  width: 1000px;
  height: 400px;
  max-width: 100%;
  max-height: 100%;
}

`, "",{"version":3,"sources":["webpack://./src/components/Home/Home.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,gBAAgB,EAAE,oCAAoC;AACxD;;AAEA;EACE,aAAa;EACb,SAAO;EACP,mBAAmB;EACnB,gBAAgB,EAAE,+BAA+B;EACjD,0BAA0B,EAAE,0CAA0C;AACxE;;AAEA;EACE,YAAY,EAAE,2BAA2B;EACzC,cAAc;EACd,YAAY,EAAE,kEAAkE;EAChF,gBAAgB,EAAE,qBAAqB;AACzC;;AAEA;EACE,SAAO;EACP,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,aAAa;EACb,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".home-container {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  overflow: hidden; /* Prevent scroll on the container */\n}\n\n.content-container {\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  margin-top: 60px; /* Space for the fixed header */\n  height: calc(100vh - 60px); /* Adjust height to fit below the header */\n}\n\n.sidebar {\n  width: 250px; /* Adjust width as needed */\n  flex-shrink: 0;\n  height: 100%; /* Make the sidebar take up full height of the content-container */\n  overflow-y: auto; /* Enable scrolling */\n}\n\n.content {\n  flex: 1;\n  padding: 20px;\n  overflow-y: auto;\n}\n\n.home-center-logo-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n\n.home-center-logo {\n  width: 1000px;\n  height: 400px;\n  max-width: 100%;\n  max-height: 100%;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
