import React from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import PropertyListItem from '../PropertyListItem/PropertyListItem';
import './SideBar.css'; // Import the CSS file

const SideBar = ({ phoneNumber, items, onItemClick }) => {
  return (
    <Sidebar style={{width: '25%', maxWidth: '350px'}}>
      <Menu>
        <MenuItem>Legal Opinions</MenuItem>
        {items.map((item) => (
          <MenuItem style={{height: 'max-content'}} key={item.id} className="menu-item" onClick={() => onItemClick(item)} >
            <PropertyListItem phoneNumber={phoneNumber} item={item} />
          </MenuItem>
        ))}
      </Menu>
    </Sidebar>
  );
};

export default SideBar;
