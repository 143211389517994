import React from 'react';
import './Header.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const Header = ({onNewOpinionClick}) => {
    const callInstructions = () => {
        alert("Call +91 7708002230 for Customer Support");
    }
    return (
        <header className="header">
        <div className="left">
            <div style={{flexDirection: 'column', justifyContent: 'flex-start'}}>
                <FontAwesomeIcon className="call-button" icon={faPhone} color="#FF6138" onClick={callInstructions}/>
            </div>
        </div>
        <div className="center">
            <img src={require('../../assets/images/header_logo.png')} alt="Logo" className="logo" />
        </div>
        <div className="right">
            <button className="new-legal-opinion-button" onClick={onNewOpinionClick}>+ New Legal Opinion</button>
        </div>
        </header>
    );
};

export default Header;
