// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  height: 100%;
  overflow-y: auto;
}

.menu-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/components/SideBar/SideBar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,6BAA6B;AAC/B","sourcesContent":[".sidebar {\n  height: 100%;\n  overflow-y: auto;\n}\n\n.menu-item {\n  padding: 10px;\n  border-bottom: 1px solid #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
