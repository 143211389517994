import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './PropertyDetails.css';
import DocumentDescriptionSection from '../DocumentDescriptionSection/DocumentDescriptionSection';
import RemarksComponent from '../RemarksComponent/RemarksComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

const PropertyDetails = ({ item, phoneNumber }) => {
  const { id, name, verificationStatus, safetyScore, remarks } = item;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    setSelectedFiles([]);
  }, [item]);
  
  const handleUploadDocument = async () => {
    console.log('initial Legal Opinion ID = ', id);
    handlePickDocument(id);
  };

  const handlePickDocument = async () => {
    document.getElementById('fileInput').click();
  };

  const uploadDocument = async (id, files) => {
    for (const file of files) {
      console.log('file = ', file);
      const formData = new FormData();
      formData.append('file', file);

      try {
        console.log('while uploading documents, phone number =', phoneNumber);
        const response = await axios.post(
          `/api/document/upload?phoneNumber=${encodeURIComponent(
            phoneNumber,
          )}&legalOpinionId=${id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              mimeType: 'multipart/form-data',
              'cache-control': 'no-cache',
            },
          },
        );

        const responseData = response.data;
        console.log('File uploaded successfully', responseData);
        setSelectedFiles(prevFiles => [...prevFiles, file.name]);
      } catch (error) {
        console.error('File upload failed', error);
      }
    }
  };

  const selectDoc = async event => {
    const files = Array.from(event.target.files);
    setLoading(true);
    await uploadDocument(id, files);
    setLoading(false);
  };

  const getStatusIcon = () => {
    if (verificationStatus === 'VERIFICATION_COMPLETE') {
      return <span style={{ color: '#34A853' }}>✔</span>;
    } else if (
      verificationStatus === 'DOCUMENT_UPLOAD_PENDING' ||
      verificationStatus === 'VERIFICATION_PENDING'
    ) {
      return <span style={{ color: '#EA4335' }}>✘</span>;
    } else if (verificationStatus === 'VERIFICATION_IN_PROGRESS') {
      return <span style={{ color: '#FFA500' }}>⌛</span>;
    }
  };

  const renderDownloadIcon1 = () => {
    if (verificationStatus === 'VERIFICATION_COMPLETE') {
      return (
        <div>
          <button onClick={downloadFile}>Download Legal Opinion</button>
          {isDownloading && (
            <div>
              <div className="spinner"></div>
              <span style={{ marginLeft: 5 }}>Downloading...</span>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  const renderDownloadIcon = () => {
    if (verificationStatus === 'VERIFICATION_COMPLETE') {
      return (
        <div>
          <button className="download-button" onClick={downloadFile} disabled={isDownloading}>
            <FontAwesomeIcon icon={faFileDownload} size="lg" color="#007BFF" />
            <span style={{marginLeft: 5}}>Click here to download the legal opinion</span>
          </button>
          {isDownloading && <div> <div className="spinner"></div><span style={{marginLeft: 5}}>Downloading...</span></div> }
        </div>
      );
    }
    return null;
  };

  const getSafetyScoreColor = () => {
    if (safetyScore >= 90) {
      return '#34A853';
    } else if (safetyScore >= 80) {
      return '#FF9900';
    }
    return '#EA4335';
  };

  const downloadFile = async () => {
    setIsDownloading(true);

    const url = `/api/document/downloadLegalOpinion/${encodeURIComponent(phoneNumber)}/${id}`;
    const response = await axios.get(url, {
      responseType: 'blob',
    });

    const urlBlob = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = urlBlob;
    link.setAttribute('download', `legal_opinion_document_${id}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();

    setIsDownloading(false);
  };

  return (
    <div className="container1">
      <div className="header-container">
        <div className="header-content">
          <div className="info-container">
            <div className="name">{name}</div>
            <div className="status-container">
              {getStatusIcon()}
              <div className="status">{verificationStatus}</div>
            </div>
            <div className="id">Legal Opinion ID: {id}</div>
            <div
              className="safety-score"
              style={{ color: getSafetyScoreColor() }}
            >
              {safetyScore || '...'}
            </div>
            <div className="safety-score-text">Safety Score</div>
            {renderDownloadIcon()}
            <div className="upload-container">
              <input
                type="file"
                id="fileInput"
                multiple
                style={{ display: 'none' }}
                onChange={selectDoc}
              />
              <button className="property-details-upload-document-button" onClick={handleUploadDocument}>Upload Documents</button>
              {loading && <div>Uploading document...</div>}
              <div>
                {selectedFiles.map((file, index) => (
                  <div key={index}>{file}</div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="details-container">
        <div className="remarks-section">
          <RemarksComponent text={remarks} />
        </div>
        <div className="upload-section">
        <DocumentDescriptionSection />
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;
