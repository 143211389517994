import React, { useState, useContext } from 'react';
import axios from 'axios';
import './NewLegalOpinion.css';
import { UserContext } from '../Context/UserContext';
import RemarksComponent from '../RemarksComponent/RemarksComponent';
import DocumentDescriptionSection from '../DocumentDescriptionSection/DocumentDescriptionSection';

const NewLegalOpinion = ({ onCancel, onNewOpinionCreated }) => {
  const { user } = useContext(UserContext);
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);
  const [legalOpinionID, setLegalOpinionID] = useState('');

  const createLegalOpinion = async () => {
    const data = {
      name,
    };

    try {
      const response = await axios.post(
        `/api/legal-opinion?phoneNumber=${encodeURIComponent(user.phoneNumber)}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      const result = response.data;
      setLegalOpinionID(result);
      onNewOpinionCreated();
      alert(`Legal opinion ID = ${result} is created to check your legal opinion.`);
    } catch (error) {
      alert('Error creating legal opinion: ' + error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await createLegalOpinion();
    setLoading(false);
  };

  return (
    <div className="new-legal-opinion">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Property Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter name of the property here"
            required
          />
        </div>
        <div style={{display: 'flex', flexDirection:'row'}}>
        <button className="create-legal-opinio-button" type="submit" disabled={loading}>
          {loading ? 'Creating...' : 'Create Legal Opinion'}
        </button>
        <button className="cancel-legal-opinio-button" ype="button" onClick={onCancel}>Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default NewLegalOpinion;
