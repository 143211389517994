import React, { createContext, useState, useEffect } from 'react';
import { encryptData, decryptData } from '../Utils/CryptoJS';

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? decryptData(storedUser) : null;
  });

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', encryptData(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
